/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./first-time-user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./first-time-user.component";
import * as i4 from "../app.service";
var styles_FirstTimeUserComponent = [i0.styles];
var RenderType_FirstTimeUserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FirstTimeUserComponent, data: {} });
export { RenderType_FirstTimeUserComponent as RenderType_FirstTimeUserComponent };
export function View_FirstTimeUserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "text-center medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("FIRST_TIME_USER.FirstTimeUserPlease")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.layoutView.supplierRegistrationLink; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("FIRST_TIME_USER.RegisterHere")); _ck(_v, 4, 0, currVal_2); }); }
export function View_FirstTimeUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-first-time-user", [], null, null, null, View_FirstTimeUserComponent_0, RenderType_FirstTimeUserComponent)), i1.ɵdid(1, 114688, null, 0, i3.FirstTimeUserComponent, [i4.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FirstTimeUserComponentNgFactory = i1.ɵccf("app-first-time-user", i3.FirstTimeUserComponent, View_FirstTimeUserComponent_Host_0, {}, {}, []);
export { FirstTimeUserComponentNgFactory as FirstTimeUserComponentNgFactory };
