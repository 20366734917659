import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChangepasswordComponent } from "./changepassword/changepassword.component";
import { FindOrganizationComponent } from "./find-organization/find-organization.component";
import { FindUserComponent } from "./find-user/find-user.component";
import { FlagsComponent } from "./flags/flags.component";
import { ForgetCredentialComponent } from "./forget-credential/forget-credential.component";
import { LoginComponent } from "./login/login.component";
import { LoginGuard } from "./login/login.guard";
import { LogoutComponent } from "./logout/logout.component";
import { MultiFactorComponent } from "./multi-factor/multi-factor.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SelectPartnerComponent } from "./select-partner/select-partner.component";
import { ResetPasswordGuard } from "./reset-password/reset-password.guard";
import { AuthenticatorComponent } from "./authenticator/authenticator.component";

const routes: Routes = [
  { path: "Logon", component: FindUserComponent },
  { path: "Login", component: LoginComponent, canActivate: [LoginGuard] },
  { path: "MfaLogin", component: MultiFactorComponent },
  { path: "SelectPartner", component: SelectPartnerComponent },
  { path: "", component: FindUserComponent, canActivate: [LoginGuard] },
  { path: "Logout", component: LogoutComponent },
  { path: "FindOrganization", component: FindOrganizationComponent },
  { path: "", pathMatch: "full", redirectTo: "Logon" },
  { path: "Flags", component: FlagsComponent },
  { path: "ChangePassword", component: ChangepasswordComponent },
  { path: "ForgetCredential", component: ForgetCredentialComponent },
  { path: "Authenticator", component: AuthenticatorComponent },
  {
    path: "ResetPassword",
    component: ResetPasswordComponent,
    canActivate: [ResetPasswordGuard],
  },
  { path: "", pathMatch: "full", redirectTo: "Logon" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
