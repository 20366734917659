import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { PartnerSelectionRequestModel } from '../models/partner-selection-request.model';
import { PartnerModel } from '../models/partner.model';
import { SelectPartnerService } from './select-partner.service';

@Component({
  selector: 'app-select-partner',
  templateUrl: './select-partner.component.html',
  styleUrls: ['./select-partner.component.scss']
})
export class SelectPartnerComponent implements OnInit {

  selectPartnerForm = new FormGroup({
    selectedPartner : new FormControl('', Validators.required),
  });

  returnUrl: string;
  firstname: string;
  lastname: string;
  partners: PartnerModel[];
  //public selectedPartner: PartnerModel;
  constructor(private appService:AppService, private router: Router, private translate : TranslateService, private titleService : Title,
    private selectPartnerService: SelectPartnerService) { }

  ngOnInit() {

    this.translate.get("/SelectPartner").subscribe(res=>{
      this.titleService.setTitle("GEP | "+res);
    });
    this.appService.titleKey = "/SelectPartner";
    this.returnUrl = this.appService.returnUrl;
    this.partners = this.appService.partners;
    this.firstname = this.appService.firstname;
    this.lastname = this.appService.lastname;    
  }

  get formControls () { return this.selectPartnerForm.controls; }

  selectPartner():void{
    if (this.selectPartnerForm.invalid){
      return;
    }
    const request: PartnerSelectionRequestModel = {
      username: this.appService.username,
      returnUrl : this.appService.returnUrl,
      partnerCode: this.selectPartnerForm.controls.selectedPartner.value,
      culture: this.appService.cultureCode
    }
    this.selectPartnerService.selectPartner(request).subscribe(result => {
      if (result && result.isSuccess){        
        window.location.href = this.appService.returnUrl;
      } else {
        //todo: place some error here
      }            
    });
  }
}
