import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faCheckCircle,
  faExclamationTriangle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, timer } from "rxjs";
import { map, take } from "rxjs/operators";
import { AppService } from "../app.service";
import { MfaLoginModel } from "../models/mfa-login.model";
import { RequestCodeModel } from "../models/request-code.model";
import { MultiFactorService } from "./multi-factor.service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: "app-multi-factor",
  templateUrl: "./multi-factor.component.html",
  styleUrls: ["./multi-factor.component.scss"],
})
export class MultiFactorComponent implements OnInit {
  okModalRef?: BsModalRef;
  okModelRefMessage: string;
  count = 180;
  private intervalId: any;
  returnUrl: string;
  isMobileOtpButNotVerified = false;
  showOtpComponent = true;
  WarningIcon = faExclamationTriangle;
  ArrowLeft = faArrowLeft;
  Tick = faCheckCircle;
  maskedPhoneNumber: string;
  maskedEmail: string;
  reloginTitle: string;
  reloginMessage: string;
  isAuthenticator = false;
  isAuthenticatorRegistrationRequired= false;
  isAuthenticatorFallbackToSms = false;
  mfaLoginForm = new FormGroup({
    code: new FormControl("", Validators.required),
  });
  isAuthenticatorFallbackToSmsAllowed= false;
  @ViewChild("blocked", { static: true })
  public blockedRef: TemplateRef<any>;
  @Output() fallbackToSmsEvent = new EventEmitter<boolean>();
  constructor(
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private multiFactorService: MultiFactorService,
    private titleService: Title,
    private translate: TranslateService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef
  ) {
    this.translate.get("MULTI_FACTOR.Relogin").subscribe((res) => {
      this.reloginTitle = res;
    });
    this.translate.get("MULTI_FACTOR.PreviousLoginExpired").subscribe((res) => {
      this.reloginMessage = res;
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.ReturnUrl) {
        this.appService.returnUrl = params.ReturnUrl;
      }
      if (params.isMobileOtpButNotVerified) {
        this.isMobileOtpButNotVerified = params.isMobileOtpButNotVerified;
      }
      // if (params.isAuthenticatorFallbackToSmsAllowed) {
      //   this.isAuthenticatorFallbackToSmsAllowed = params.isAuthenticatorFallbackToSmsAllowed;
      // }
      this.returnUrl = this.appService.returnUrl;
    });
    this.translate.get("/Login").subscribe((res) => {
      this.titleService.setTitle("GEP | " + res);
    });
    this.appService.titleKey = "/Login";
    this.maskedEmail = this.appService.maskedEmail;
    this.maskedPhoneNumber = this.appService.maskedPhoneNumber;
    this.count = this.appService.reRequestVerificationCodeSeconds;
    if (this.appService.authResult){// this authResult is populated only when the call is from authenticator
      this.isAuthenticator = this.appService.authResult.isAuthenticator;
      this.isAuthenticatorFallbackToSmsAllowed = this.appService.authResult.isAuthenticatorFallbackToSmsAllowed;
      this.isAuthenticatorRegistrationRequired = this.appService.authResult.isAuthenticatorRegistrationRequired;
      this.isMobileOtpButNotVerified = this.appService.authResult.isMobileOtpButNotVerified;
      this.maskedPhoneNumber = this.appService.authResult.maskedPhoneNumber;    
      this.count = this.appService.authResult.reRequestVerificationCodeSeconds;
    }
    
    if (this.maskedEmail && this.maskedPhoneNumber) {
      this.mfaLoginForm.addControl(
        "mfaChannel",
        new FormControl("email", Validators.required)
      );
    }
    this.resetCounter();
  }

  get formControls() {
    return this.mfaLoginForm.controls;
  }

  login(): void {
    if (this.mfaLoginForm.invalid) {
      return;
    }
    const request: MfaLoginModel = {
      code: this.mfaLoginForm.value.code,
      returnUrl: this.appService.returnUrl,
      rememberMachine: false,
      culture: this.appService.cultureCode,
    };
    if (this.maskedEmail && this.maskedPhoneNumber) {
      request.mfaChannel = this.mfaLoginForm.value.mfaChannel;
    } else if ((this.maskedPhoneNumber && !this.isAuthenticator) || (this.isAuthenticator && this.isAuthenticatorFallbackToSms)) {
      request.mfaChannel = "1"; //SMS
    } else if (this.maskedEmail) {
      request.mfaChannel = "2"; //Email
    } else if (this.isAuthenticator){
      request.mfaChannel = "4"; //Authenticator
    }
    this.multiFactorService.login(request).subscribe((result) => {
      if (result.inCorrectCode) {
        this.formControls.code.setErrors({ incorrectCode: true });
        return;
      } else if (result.isLockedOut || result.isNotAllowed) {
        //User locked out and should be blocked. This happens only when the mfa attempts has exceeded in the context of MFA validation.
        this.okModelRefMessage = "MULTI_FACTOR.ExceededMaxMfaAttempts";
        this.okModalRef = this.modalService.show(this.blockedRef, {
          class: "modal-dialog-centered",
        });
        return;
      } else if (result.isExpired) {
        this.okModelRefMessage = "MULTI_FACTOR.OtpExpired";
      } else if (result.isMaxAttemptExceeded) {
        //As per security requirements, when the count has exceeded, the user gets locked, so this case might not be hit
        this.okModelRefMessage = "MULTI_FACTOR.ExceededMaxMfaAttempts";
      } else if (result.isPartnerSelection) {
        this.appService.partners = result.partners;
        this.appService.firstname = result.firstname;
        this.appService.lastname = result.lastname;
        this.router.navigate(["/SelectPartner"], {
          queryParams: { ReturnUrl: this.appService.returnUrl },
        });
        return;
      }
      window.location.href = this.appService.returnUrl;
    });
  }

  requestToken(isAuthenticatorFallbackToSms: boolean): void {
    const request: RequestCodeModel = {
      returnUrl: this.appService.returnUrl,
      cultureCode: this.appService.cultureCode,
      isAuthenticatorFallbackToSms:isAuthenticatorFallbackToSms
    };
    this.multiFactorService.requestToken(request).subscribe((result) => {
      if (result.isSuccess) {
        if (isAuthenticatorFallbackToSms){
          this.isAuthenticatorFallbackToSms = true;  
          this.fallbackToSmsEvent.emit(this.isAuthenticatorFallbackToSms);      
        }
        this.count = this.appService.reRequestVerificationCodeSeconds ? this.appService.reRequestVerificationCodeSeconds:this.appService.authResult.reRequestVerificationCodeSeconds ;
        this.resetCounter();
        this.cdr.detectChanges();
      } else {
        this.appService.showWarning(this.reloginTitle, this.reloginMessage);
        this.router.navigate(["/Login"], {
          queryParams: { ReturnUrl: this.appService.returnUrl },
        });
      }
    });
  }
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  resetCounter() {   
    this.intervalId = setInterval(() => {
      if (this.count > 0) {
        this.count--;
      } else {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }
  goBack() {
    this.deleteMfaCookies();
    this.router.navigate(["/Logon"], {
      queryParams: { ReturnUrl: this.appService.returnUrl },
    });
  }
  tofinduser(): void {
    if (this.okModalRef) {
      this.okModalRef.hide();
    }

    this.goBack();
  }

  private deleteMfaCookies() {
    this.cookieService.delete("Identity.TwoFactorUserId");
    this.cookieService.delete("mf_user");
  }

  unableToUseAuthenticator(e): void {
    e.preventDefault();// Prevent the default action of the a tag
    this.requestToken(true);
  }
}
