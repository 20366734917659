<form [formGroup]="signInForm" class="form-floating" (ngSubmit)="signIn()" #form="ngForm" autocomplete="off">
  <div class="mb-3"> </div>
    <div class="d-flex justify-content-end mb-1 mt-2 "><a routerLink="/ForgetCredential" [queryParams]= "{ReturnUrl: returnUrl}"><span class="small">{{
        'ForgotUsername' | translate}}?</span> </a> </div>
    <div class="form-floating mb-3">
        <input id="userId" type="text" maxlength="50" class="form-control" formControlName="userId" [attr.disabled]="true">
        <label for="userId">{{ 'Username' | translate}} </label>
    </div>
    <div class="d-flex justify-content-end mb-1 mt-2 "><a routerLink="/ForgetCredential" [queryParams]= "{ReturnUrl: returnUrl}" (click)="forgotPasswordTab()"><span class="small">{{
                'LOGIN.ForgotPassword' | translate}}</span> </a> </div>
    <div class="form-floating mb-3">
        <input autofocus id="password" [type]="showPassword ? 'text' : 'password'"class="form-control small" formControlName="password" [ngClass]="{ 'is-invalid': (formControls.password.dirty || formControls.password.touched) && form.submitted
                && formControls.password.invalid , 'is-valid': (formControls.password.dirty || formControls.password.touched)
                && formControls.password.valid }" placeholder="Password"
                [attr.aria-invalid] = "(formControls.password.dirty || formControls.password.touched ) && form.submitted
                && formControls.password.invalid">
        <label for="password">{{ 'Password' | translate}} </label>
        <div class="invalid-feedback small text-justify">
              <div *ngIf="formControls.password.errors?.required" aria-invalid="true" role="alert" >
                <span class="position-absolute"> {{ 'LOGIN.PasswordRequired' | translate}}</span>
              </div>
            <div *ngIf="formControls.password.errors?.invalidCredentials" aria-invalid="true" role="alert">
                {{ 'LOGIN.AccountError' | translate}}
            </div>
            <div *ngIf="formControls.password.errors?.isTwoFactorException" aria-invalid="true" role="alert">
              {{ 'LOGIN.TwoFactorSendError' | translate}}
            </div>            
        </div>
        <div class="eyetriangle">
          <span class="px-2">
            <i><fa-icon class = "red-text" [icon]="WarningIcon" *ngIf="formControls.password.invalid && formControls.password.touched  && form.submitted"></fa-icon>  </i>
        </span>
        <fa-icon class = "eye" [icon]="Eye" (click)="togglePassword()" [class.hide]="!showPassword "></fa-icon>
      <fa-icon class = "eye" [icon]="EyeSlash" (click)="togglePassword()" [class.hide]="showPassword"></fa-icon>
        </div>
    </div>
    <div class="d-flex align-items-center">
        <button type="submit" class="btn btn-primary btn-block ms-auto px-3 mt-2">
            {{ 'SignIn' | translate}}

        </button>
    </div>
</form>
<div class="text-center medium">
  <a routerLink="/Logon" [queryParams]= "{ReturnUrl: returnUrl}">{{'SignInWithDifferentUser'|translate}}</a>
</div>
<app-first-time-user></app-first-time-user>


<ng-template #blocked>
  <div class="modal-body pb-0 small">
    <div class="d-flex align-items-center mb-2">
      <span class="modal-message-icon"><fa-icon class = "red-text"  [icon]="WarningIcon"></fa-icon></span>
        <span class="large px-2">{{'Warning' | translate}}</span>
      </div>
    <p class="mt-4">{{'LOGIN.Blocked' | translate}}</p>
  </div>
  <div class="modal-footer pt-0 small">
    <button type="button" class="btn btn-primary" (click)="tofinduser()" >Ok</button>
  </div>
</ng-template>

