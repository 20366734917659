import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "./login.service";
import { AppService } from "../app.service";
import { LoginViewModel } from "../models/login-view.model";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  signInForm: FormGroup;
  okModalRef?: BsModalRef;
  WarningIcon = faExclamationTriangle;
  Tick = faCheckCircle;
  Eye = faEye;
  EyeSlash = faEyeSlash;
  public showPassword: boolean;

  @ViewChild("blocked", { static: true })
  public blockedRef: TemplateRef<any>;

  constructor(
    private loginService: LoginService,
    private appService: AppService,
    private router: Router,
    private modalService: BsModalService,
    private translate: TranslateService,
    private titleService: Title
  ) {}

  get formControls() {
    return this.signInForm.controls;
  }

  ngOnInit(): void {
    //const loginContext = this.appService.getLoginContext();
    this.signInForm = new FormGroup({
      userId: new FormControl(this.appService.username, Validators.required),
      password: new FormControl("", Validators.required),
    });

    this.translate.get("/Login").subscribe((res) => {
      this.titleService.setTitle("GEP | " + res);
    });
    this.appService.titleKey = "/Login";
    this.returnUrl = this.appService.returnUrl;
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }
  signIn(): void {
    if (this.signInForm.invalid) {
      return;
    }
    this.appService.password = this.signInForm.value.password;
    const signin: LoginViewModel = {
      username: this.signInForm.value.userId,
      password: this.signInForm.value.password,
      returnUrl: this.appService.returnUrl,
      culture: this.appService.cultureCode,
    };

    this.loginService.login(signin).subscribe(
      (authResult) => {
        if (authResult.isLockedOut || authResult.isNotAllowed) {
          //User locked out and should be blocked
          this.okModalRef = this.modalService.show(this.blockedRef, {
            class: "modal-dialog-centered",
          });
          return;
        } else if (authResult.isInvalidCredentials) {
          //Let it go to the login and then show the login auth message
          //In case auth message is there, it would already be responded from idp and it can be shown on UI
          setTimeout(() => {
            this.signInForm.controls.password.setErrors({
              invalidCredentials: true,
            });
            this.signInForm.controls.password.markAsTouched();
          }, 100);
          return;
        } else if (authResult.isInvalidCredentials) {
          //Let it go to the login and then show the login auth message
          //In case auth message is there, it would already be responded from idp and it can be shown on UI
          this.loginFailed();
          return;
        } else if (authResult.requiresTwoFactor) {
          if (authResult.isTwoFactorException) {
            setTimeout(() => {
              this.signInForm.controls.password.setErrors({
                isTwoFactorException: true,
              });
              this.signInForm.controls.password.markAsTouched();
            }, 100);
            return;
          }
          this.appService.authResult = authResult; 
          if (authResult.isAuthenticator){
            if (authResult.isAuthenticatorRegistrationRequired){
              //route to registration page                         
              this.router.navigate(["/Authenticator"]);
              return;
            }
          }
          
          this.appService.maskedEmail = authResult.maskedEmail;
          this.appService.maskedPhoneNumber = authResult.maskedPhoneNumber;
          this.appService.reRequestVerificationCodeSeconds =
            authResult.reRequestVerificationCodeSeconds;
          this.router.navigate(["/MfaLogin"], {
            queryParams: {
              ReturnUrl: this.appService.returnUrl,
              isMobileOtpButNotVerified: authResult.isMobileOtpButNotVerified,
            },
          });
          return;       
        } else if (authResult.isPartnerSelection) {
          this.appService.partners = authResult.partners;
          this.appService.firstname = authResult.firstname;
          this.appService.lastname = authResult.lastname;
          this.appService.contextChangeGet().subscribe(() => {
            this.router.navigate(["/SelectPartner"], {
              queryParams: { ReturnUrl: this.appService.returnUrl },
            });
          }); //refresh xsrf and then route as the login context changed
          return;
        }
        window.location.href = this.appService.returnUrl;
      },
      () => this.loginFailed()
    );
  }

  private loginFailed() {
    setTimeout(() => {
      this.signInForm.controls.password.setErrors({ invalidCredentials: true });
      this.signInForm.controls.password.markAsTouched();
    }, 100);
  }
  tofinduser(): void {
    if (this.okModalRef) {
      this.okModalRef.hide();
    }
    this.router.navigate(["/Logon"], {
      queryParams: { ReturnUrl: this.appService.returnUrl },
    });
  }

  toChangePassword(): void {
    if (this.okModalRef) {
      this.okModalRef.hide();
    }
    this.router.navigate(["/ChangePassword"], {
      queryParams: { ReturnUrl: this.appService.returnUrl },
    });
  }

  forgotPasswordTab(): void {
    this.appService.forgotPasswordTab = false;
  }
}
