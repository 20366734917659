import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.service";
export class ChangePasswordService {
    constructor(httpClient, appService) {
        this.httpClient = httpClient;
        this.appService = appService;
    }
    changePassword(changePassword) {
        let changePasswordInput = JSON.parse(JSON.stringify(changePassword));
        changePasswordInput.currentPassword = this.appService.aesEncrypt(changePasswordInput.username, changePasswordInput.currentPassword);
        changePasswordInput.newPassword = this.appService.aesEncrypt(changePasswordInput.username, changePasswordInput.newPassword);
        changePasswordInput.confirmPassword = this.appService.aesEncrypt(changePasswordInput.username, changePasswordInput.confirmPassword);
        return this.httpClient.post("/Account/ChangePassword", changePasswordInput);
    }
}
ChangePasswordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChangePasswordService_Factory() { return new ChangePasswordService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppService)); }, token: ChangePasswordService, providedIn: "root" });
