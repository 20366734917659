import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { LayoutViewModel } from '../models/layout-view.model';

@Component({
  selector: 'app-supplier-registration',
  templateUrl: './supplier-registration.component.html',
  styleUrls: ['./supplier-registration.component.scss']
})
export class SupplierRegistrationComponent implements OnInit {
  layoutView: LayoutViewModel;
  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.layoutView = this.appService.layoutView;
  }

}
