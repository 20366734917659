import * as cryptoJS from 'crypto-js';
//import cryptoJS from 'crypto-js/aes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AesEncryptionService {

  //Secret key and vector key will both be stored in the form of a WordArray
  private _secretKey: string = null;
  private _vectorKey: string = null;
  get secretKey(): string {
    return this._secretKey;
  }
  get vectorKey(): string  {
    return this._vectorKey;
  }

  set secretKey(keyToSet) {
    if (keyToSet != null && keyToSet != undefined && keyToSet != "") {
      keyToSet = this.pad_with_zeroes(keyToSet, 32);
      this._secretKey = cryptoJS.enc.Utf8.parse(keyToSet.substring(0, 32));
    }
  }
    
  set vectorKey(keyToSet) {
    if (keyToSet != null && keyToSet != "") {
      keyToSet = this.pad_with_zeroes(keyToSet, 16);
      this._vectorKey = cryptoJS.enc.Utf8.parse(keyToSet.substring(0, 16));
    }
  }
  constructor() { }

  //Encrypts the data based on the secret key and the vector key set beforehand
  encryptData(dataToEncrypt) {
    var encryptedString = "";
    if (dataToEncrypt != "" && dataToEncrypt != null) {
      var resultObject = cryptoJS.AES.encrypt(dataToEncrypt, this._secretKey, { iv:this._vectorKey });
      encryptedString = (resultObject.ciphertext).toString(cryptoJS.enc.Base64);
      return encryptedString.replace("/", "*");
    }
  }
  // Decrypts the data based on the secret key and the vector key set beforehand   
  decryptData(dataToDecrypt) {
    if (dataToDecrypt != null && dataToDecrypt != undefined) {
      const decryptedString = cryptoJS.AES.decrypt();
      return decryptedString;
    }
  }

  pad_with_zeroes(inputString, length) {
    while (inputString.length <= length) {
      inputString = inputString + '0';
    }
    return inputString;
  }
}





