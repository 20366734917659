import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecoverPasswordResultModel } from '../models/recover-password-result.model';
import { RecoverPasswordModel } from '../models/recover-password.model';
import { RecoverUsernamesResult } from '../models/recover-usernames-result.model';
import { RecoverUsernamesModel } from '../models/recover-usernames.model';



@Injectable({
  providedIn: 'root'
})
export class ForgetCredentialService {

  constructor(private httpClient: HttpClient) { }

  recoverPassword(model: RecoverPasswordModel):Observable<RecoverPasswordResultModel>{
    return this.httpClient.post<RecoverPasswordResultModel>("/Account/RecoverPassword",model);
  }

  recoverUsernames(model: RecoverUsernamesModel):Observable<RecoverUsernamesResult>{
    return this.httpClient.post<RecoverUsernamesResult>("/Account/RecoverUserNames",model);
  }  
}
