import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
const ɵ0 = (createTranslateLoader);
export class AppModule {
}
export function createTranslateLoader(http) {
    let element = document.getElementById("LayoutModel");
    let buildNumberElement = document.getElementById("BuildNumber");
    let buildNumber = buildNumberElement ? buildNumberElement.value : '';
    if (buildNumber == "#{BuildNumber}#") {
        buildNumber = '';
    }
    if (buildNumber) {
        buildNumber = "/" + buildNumber;
    }
    const layoutView = JSON.parse(element.value);
    if (!layoutView.cdnPath) {
        layoutView.cdnPath = '';
    }
    layoutView.cdnPath = layoutView.cdnPath + buildNumber;
    return new TranslateHttpLoader(http, layoutView.cdnPath + '/assets/i18n/', '.json');
}
export { ɵ0 };
