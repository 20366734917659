<div class="large mt-4 mb-1">
    {{'Hello' | translate}} {{firstname}} {{lastname}}!
</div>
<div class="mb-2 medium">
    {{'SELECT_PARTNER.selectProfileText' | translate}}
</div>

<form [formGroup]="selectPartnerForm" class="form-floating" (ngSubmit)="selectPartner()" #form="ngForm" autocomplete="off">
    <div class="partner-view">
        <div *ngFor="let partner of partners" class="aligned justify-content-center py-2">

            <div class="col-1 d-flex align-self-center justify-content-center" >
                <input type="radio" [value]="partner.partnerCode" formControlName="selectedPartner"
                [ngClass]="{ 'is-invalid': (formControls.selectedPartner.dirty || formControls.selectedPartner.touched || form.submitted)
                && formControls.selectedPartner.invalid , 'is-valid': (formControls.selectedPartner.dirty || formControls.selectedPartner.touched)
                && formControls.selectedPartner.valid }"
                [attr.aria-invalid]="(formControls.selectedPartner.dirty || formControls.selectedPartner.touched || form.submitted)
                && formControls.selectedPartner.invalid ">
            </div>
            <div class="col-10 px-2" >
                <div class="aligned">
                   <img style="width:100px" class="partner-logo" [src]="partner.logoUrl">
                    <div class="partnertext px-2">
                        <div class="legalname">{{partner.legalCompanyName}}</div>
                        <div class="clientname medium">{{partner.clientSupplierCode}}</div>
                    </div>
                </div>

            </div>
        </div>       
    </div>      
    <div class="small position-absolute red-text" *ngIf="formControls.selectedPartner.dirty || formControls.selectedPartner.touched || form.submitted">
        <div *ngIf="formControls.selectedPartner.errors?.required" aria-invalid="true" role="alert">
            {{ 'SELECT_PARTNER.selectProfileText' | translate}}
        </div>
    </div>
    <div class="d-flex align-items-center  mt-3 ">
        <button type="submit" class="btn btn-primary btn-block">
            {{'Proceed' | translate}}
        </button>
    </div>
</form>