import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { MfaLoginResultModel } from '../models/mfa-login-result.model';
import { MfaLoginModel } from '../models/mfa-login.model';
import { RequestCodeResponseModel } from '../models/request-code-response.model';
import { RequestCodeModel } from '../models/request-code.model';

@Injectable({
  providedIn: 'root'
})
export class MultiFactorService {

  constructor(private httpClient: HttpClient) { }

  login(request: MfaLoginModel): Observable<MfaLoginResultModel> {
    return this.httpClient.post<MfaLoginResultModel>("/Account/MfaLogin", request);
  }
  requestToken(request: RequestCodeModel): Observable<RequestCodeResponseModel> {   
    return this.httpClient.post<any>("/Account/RequestCode", request);
  }
  
}
