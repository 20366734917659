import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePasswordModel } from '../models/change-password.model';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private httpClient : HttpClient, private appService: AppService ) { }

  changePassword(changePassword: ChangePasswordModel) :Observable<any>
  {
    let changePasswordInput : ChangePasswordModel = JSON.parse(JSON.stringify(changePassword));
    changePasswordInput.currentPassword = this.appService.aesEncrypt(changePasswordInput.username, changePasswordInput.currentPassword);
    changePasswordInput.newPassword = this.appService.aesEncrypt(changePasswordInput.username, changePasswordInput.newPassword);
    changePasswordInput.confirmPassword = this.appService.aesEncrypt(changePasswordInput.username, changePasswordInput.confirmPassword);
    
    return this.httpClient.post("/Account/ChangePassword", changePasswordInput);
  }

}
