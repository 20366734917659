import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { LoginViewModel } from '../models/login-view.model';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private appService: AppService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const returnUrl = next.queryParams.ReturnUrl;
      if (returnUrl){
        this.appService.returnUrl = returnUrl;
      }


      if (!this.appService.username){
        this.router.navigate(['/Logon'], { queryParams: next.queryParams });
        return false;
      }
      return true;
  }

}
