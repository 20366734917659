import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './app.service';
import { LoadingService } from './loading.service';
import { LayoutViewModel } from './models/layout-view.model';
import {delay} from 'rxjs/operators';
import { ImageLink } from './models/image-link.model';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute,NavigationEnd,Event } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('bannerText', [       
        transition('* => *', [
          style({opacity: 0}), 
          animate('700ms', style({opacity: 0.25})),         
          animate('1400ms', style({opacity: 1}))
        ])
    ])]
})
export class AppComponent  implements OnInit {
  loading: boolean = false;
  imageStyle = "";
  title = 'GEP | Login';
  cdnPath = '';
  bannerTexts: string[];
  infoTexts: string[];
  imageLinks: ImageLink[];
  private jsonUrl = 'assets/background.json';
  constructor(private appService: AppService,private translate: TranslateService, private loadingService: LoadingService,
    private httpClient: HttpClient, private titleService: Title, private router : Router) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en-US');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en-US');
  }
  bgcounter = 0;
  currentImage: ImageLink;
  currentImagePath: string;
  bannerText: string;
  infoText : string;
  infoLink: string;

  ngOnInit(): void {
    const appTitle = this.titleService.getTitle();
    this.listenToLoading();
    let imageLinksJson = this.getElementValue("ImageLinks");
    let layoutModelJson = this.getElementValue("LayoutModel");
    let buildNumber = this.getElementValue("BuildNumber");
    if (buildNumber == "#{BuildNumber}#"){
      buildNumber = '';
    }
    if (buildNumber){
      buildNumber = "/" + buildNumber ;
    }
    const layoutView = <LayoutViewModel>JSON.parse(layoutModelJson);
    this.imageLinks = <ImageLink[]>JSON.parse(imageLinksJson);
    layoutView.supportGroupEmail = "mailto:"+ layoutView.supportGroupEmail;
    this.appService.layoutView = layoutView;
    if (!this.appService.layoutView.cdnPath){
      this.appService.layoutView.cdnPath = '';
    }
    this.appService.layoutView.cdnPath = this.appService.layoutView.cdnPath+ buildNumber 
    const seconds:number = this.appService.layoutView.bannerImageTransitionTimeInSeconds;
    const cdnpath = this.appService.layoutView.cdnPath;
    this.currentImagePath  = cdnpath + "/assets/images/shutterstock1.jpg";
    const root= document.documentElement;
  root.style.setProperty('--seconds', seconds.toString()+'s');

    this.cdnPath = this.appService.layoutView.cdnPath;
    this.jsonUrl = this.cdnPath+ "/"+ this.jsonUrl
    this.getBackgroundJson();
    const url= this.router.url;
    this.translate.get(url).subscribe(res=>{
      this.titleService.setTitle("GEP | "+res);
    });

    this.appService.flagSelection.subscribe(() => this.handleBackground());
    this.addFonts();
  }
  addFonts() {
    var fontStyle = document.createElement('style');
    fontStyle.appendChild(document.createTextNode(`
      @font-face {
        font-family: 'Font Awesome 5 Free';
        font-style: normal;
        font-weight: 900;
        font-display: block;    
        src: url("${this.appService.layoutView.cdnPath}/assets/webfonts/fa-solid-900.eot");
        src: url("${this.appService.layoutView.cdnPath}/assets/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("${this.appService.layoutView.cdnPath}/assets/webfonts/fa-solid-900.woff2") format("woff2"), url("${this.appService.layoutView.cdnPath}/assets/webfonts/fa-solid-900.woff") format("woff"), url("${this.appService.layoutView.cdnPath}/assets/webfonts/fa-solid-900.ttf") format("truetype"), url("${this.appService.layoutView.cdnPath}/assets/webfonts/fa-solid-900.svg#fontawesome") format("svg"); } 
      }
    `));
    document.head.appendChild(fontStyle);
  }

  getBackgroundJson() {
      this.appService.layoutView.backgroundImages = this.imageLinks;
      this.handleBackground();
  }

  handleBackground() {
    if (this.appService.layoutView.backgroundImages) {
      this.currentImage = this.appService.layoutView.backgroundImages[0];
      this.setBackground(this.currentImage);
      setInterval(() => {
        this.bgcounter++;
        if (this.bgcounter > this.appService.layoutView.backgroundImages.length - 1) {
          this.bgcounter = 0;
        }
        this.currentImage = this.appService.layoutView.backgroundImages[this.bgcounter];
        this.setBackground(this.currentImage);
      }, this.appService.layoutView.bannerImageTransitionTimeInSeconds * 1000);
    }
  }

  setBackground(image: ImageLink): void {
    this.currentImagePath  = image.path;
    this.infoLink = image.link;
    this.translate.get("bannerText."+ image.name).subscribe((res: string) => {
      this.bannerText = res;
    });
    this.translate.get("infoText."+ image.name).subscribe((res: string) => {
      this.infoText = res;
    });
  }

  getElementValue(id: string): string{
    let element = <HTMLInputElement>document.getElementById(id);
    if (element){
      return element.value;
    }
    return "";
  }

  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
