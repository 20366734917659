import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.scss']
})
export class FlagsComponent implements OnInit {

  selectedCountryCode = 'en-US';
  cCodes =[];
  countryCodes = [];
  x:any;
  locales = {};
  public title ;
  showFlags = true;
  showLabels = true;
  config = {};
  dropdownOptions = {};
  selectedLanguage = [];
  constructor(private translationService: TranslateService, private router : Router, private titleService : Title , private appService : AppService,
    private httpClient: HttpClient) {
      this.locales = [];
      this.config =  {
        displayFn:(item: any) => { return item.language; },
        displayKey:"language",
        search:false, 
        height: 'auto',
        placeholder:'', 
        customComparator:(a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0, 
        limitTo: 0, 
        moreText: 'more' ,
        noResultsFound: 'No results found!',
        searchPlaceholder:'Search',
        searchOnKey: 'name', 
        clearOnSelection: false,                
      }
  }

  ngOnInit(): void {    
    const cdnPath = this.appService.layoutView.cdnPath;
    const jsonUrl = cdnPath+ "/"+ 'assets/i18n/locales.json'
    this.httpClient.get(jsonUrl)
    .subscribe((result:any[]) =>{
      this.locales = result;
      this.selectedLanguage = result.filter(r => r.language === "English");
      this.config["customComparator"] = () => {return result};                  
    });
  }

  changeSelectedCountryCode(language: any): void {    
    this.appService.cultureCode = language.value.code;
    this.translationService.use(language.value.code).subscribe(result => {
      this.translationService.get(this.appService.titleKey).subscribe(res=>{
        this.title= res;
      });
      this.titleService.setTitle("GEP | "+this.title);
    }) ;    
    this.appService.flagSelectionChanged();
    this.selectedCountryCode = language.value;    
  }  
}
