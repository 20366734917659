import { Component, OnInit , TemplateRef, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { FindUserService } from './find-user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faExclamationTriangle,faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FindUserViewModel } from '../models/find-user.model';

@Component({
  selector: 'app-find-user',
  templateUrl: './find-user.component.html',
  styleUrls: ['./find-user.component.scss']
})
export class FindUserComponent implements OnInit {
  returnUrl: string;
    findUserForm = new FormGroup({
    userId : new FormControl('', Validators.required),
  });
  okModalRef: BsModalRef;
  WarningIcon = faExclamationTriangle;
  Tick= faCheckCircle;
  public showPassword: boolean;

  @ViewChild('passwordExpired',{static:true})
  public passwordExpiredRef: TemplateRef<any>;

  constructor(private appService: AppService, private router: Router, private route: ActivatedRoute, private modalService:  BsModalService
    ,private findUserService: FindUserService,private titleService : Title , private translate : TranslateService) {
    }

  ngOnInit() {    
    this.route.queryParams
    .subscribe(params => {
      if (params.ReturnUrl){
        this.appService.returnUrl = params.ReturnUrl;
      }
      this.returnUrl = this.appService.returnUrl;
    });
    this.translate.get("/FindUser").subscribe(res=>{
      this.titleService.setTitle("GEP | "+res);
    });
    this.appService.titleKey = "/FindUser";
  }

  get formControls () { return this.findUserForm.controls; }

  findUser():void{

    if (this.findUserForm.invalid){
      return;
    }
    this.appService.username = this.findUserForm.value.userId;
    const request: FindUserViewModel = {
      username: this.findUserForm.value.userId,
      returnUrl : this.appService.returnUrl
    }
    this.findUserService.findUser(request).subscribe(result => {
      if (result){
        if (result.isUserSso) {
          if (result.redirectUrl) {
            window.location.href = result.redirectUrl;   
            return;
          } else {
            //TODO: What to do here - data issue
          }
        }
        else if (result.mustChangePassword){
          this.okModalRef = this.modalService.show(this.passwordExpiredRef, {class: 'modal-dialog-centered'});  
          return;
        }
      }      
      this.router.navigate(["/Login"], { queryParams: {ReturnUrl: this.appService.returnUrl} });
    });
  }
  forgotPasswordTab(): void{
    this.appService.forgotPasswordTab = true;
   }

  toChangePassword(): void{
    this.okModalRef.hide()
    this.router.navigate(["/ChangePassword"], { queryParams: {ReturnUrl: this.appService.returnUrl} });
  }
}
