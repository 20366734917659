import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { FindOrganizationService } from './find-organization.service';
import { faExclamationTriangle,faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FindOrganizationModel } from '../models/find-organization.model';


@Component({
  selector: 'app-find-organization',
  templateUrl: './find-organization.component.html',
  styleUrls: ['./find-organization.component.scss']
})
export class FindOrganizationComponent implements OnInit {
  returnUrl: string;
  findOrganizationForm = new FormGroup({
    domainName : new FormControl('', Validators.required),
  });
  WarningIcon = faExclamationTriangle;
  Tick= faCheckCircle;

  constructor(private appService: AppService, private router: Router, private route: ActivatedRoute ,private findOrganizationService : FindOrganizationService, private titleService : Title, private translate : TranslateService) { }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      if (params.ReturnUrl){
        this.appService.returnUrl = params.ReturnUrl;
      }
      this.returnUrl = this.appService.returnUrl;
    });
    this.translate.get('/FindOrganization').subscribe(res=>{
      this.titleService.setTitle("GEP | "+res);
    });
    this.appService.titleKey = "/FindOrganization";

  }

  get formControls () { return this.findOrganizationForm.controls; }

  findOrganization():void{
    if (this.findOrganizationForm.invalid){
      return;
    }
    this.appService.organizationName  = this.findOrganizationForm.value.domainName;
    const request: FindOrganizationModel = {
        domainName: this.findOrganizationForm.value.domainName,
        returnUrl: this.appService.returnUrl
    }

    this.findOrganizationService.findOrganization(request).subscribe(result => {
      if (result.isDomainNotFound || ! result.redirectUrl){
        this.formControls.domainName.setErrors({domainNotFound: true});
        return;
      }
      window.location.href = result.redirectUrl;      
    });
  }
}
