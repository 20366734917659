<form [formGroup]="findUserForm" class="form-floating" (ngSubmit)="findUser()" #form="ngForm" autocomplete="off">
    <div class="mt-1">&nbsp; </div>
    <div class="d-flex justify-content-end mb-1 "><a routerLink="/ForgetCredential" [queryParams]= "{ReturnUrl: returnUrl}"  (click)="forgotPasswordTab()"><span class="small">{{
                'ForgotUsername' | translate}}?</span> </a> </div>
    <div class="form-floating align-self-center">
        <input autofocus id="userId" maxlength="50" type="text" class="form-control" formControlName="userId" [ngClass]="{ 'is-invalid': (formControls.userId.dirty || formControls.userId.touched || form.submitted)
                && formControls.userId.invalid && form.submitted, 'is-valid': (formControls.userId.dirty || formControls.userId.touched)
                && formControls.userId.valid }" placeholder="Username" appUsernameValidation
                [attr.aria-invalid]="(formControls.userId.invalid && form.submitted)
                && formControls.userId.invalid" >
        <label for="userId">{{ 'Username' | translate}} </label>
        <div class="eyetriangle">
          <span class="px-2">
         <i><fa-icon class = "red-text" [icon]="WarningIcon" *ngIf="formControls.userId.invalid && form.submitted"></fa-icon>  </i>
        </span>
        </div>
        <div class="invalid-feedback small position-absolute">
            <div *ngIf="formControls.userId.errors?.required && form.submitted" aria-invalid="true" role="alert">
                {{ 'FIND_USER.UsernameRequiredMessage' | translate}}
            </div>
        </div>
 </div>
    <div class="d-flex align-items-center mt-4">
        <button type="submit" class="btn btn-block btn-primary px-4 py-1 mt-4">
            {{'Next' | translate}}
        </button>
    </div>
</form>
<div class="text-center mt-2 medium">
    <a routerLink="/FindOrganization" [queryParams]= "{ReturnUrl: returnUrl}" attr.aria-label="{{'SignIn' | translate}} {{'FIND_USER.ToOrg' | translate}}">{{'SignIn' | translate}}</a> {{'FIND_USER.ToOrg' | translate}}
</div>
<div class="mt-3 medium">
    <app-supplier-registration></app-supplier-registration>
</div>

<ng-template #passwordExpired>
  <div class="modal-body pb-0 small">
    <div class="d-flex align-items-center mb-2">
      <span class="modal-message-icon"><fa-icon [icon]="WarningIcon"></fa-icon></span>
        <span class="large px-2">{{'Warning' | translate}}</span>
      </div>
    <p class="mt-4">{{'LOGIN.PasswordExpired' | translate}}</p>
  </div>
  <div class="modal-footer pt-0 small">
    <button type="button" class="btn btn-primary" (click)="toChangePassword()">Ok</button>
  </div>
</ng-template>
