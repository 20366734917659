/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./supplier-registration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./supplier-registration.component";
import * as i4 from "../app.service";
var styles_SupplierRegistrationComponent = [i0.styles];
var RenderType_SupplierRegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupplierRegistrationComponent, data: {} });
export { RenderType_SupplierRegistrationComponent as RenderType_SupplierRegistrationComponent };
export function View_SupplierRegistrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "text-center medium"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["target", "_blank"]], [[8, "href", 4], [1, "aria-label", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(6, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layoutView.supplierRegistrationLink; var currVal_1 = i1.ɵinlineInterpolate(2, "", i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform("Supplier_Registration.ClickHere")), " ", i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform("Supplier_Registration.ToRegisterAsASupplier")), ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Supplier_Registration.ClickHere")); _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Supplier_Registration.ToRegisterAsASupplier")); _ck(_v, 6, 0, currVal_3); }); }
export function View_SupplierRegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-supplier-registration", [], null, null, null, View_SupplierRegistrationComponent_0, RenderType_SupplierRegistrationComponent)), i1.ɵdid(1, 114688, null, 0, i3.SupplierRegistrationComponent, [i4.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupplierRegistrationComponentNgFactory = i1.ɵccf("app-supplier-registration", i3.SupplierRegistrationComponent, View_SupplierRegistrationComponent_Host_0, {}, {}, []);
export { SupplierRegistrationComponentNgFactory as SupplierRegistrationComponentNgFactory };
