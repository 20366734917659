import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SelectPartnerService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    selectPartner(request) {
        return this.httpClient.post("/Account/SelectPartner", request);
    }
}
SelectPartnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SelectPartnerService_Factory() { return new SelectPartnerService(i0.ɵɵinject(i1.HttpClient)); }, token: SelectPartnerService, providedIn: "root" });
