import { HttpClient } from "@angular/common/http";
import { AppService } from "../app.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.service";
export class ResetPasswordService {
    constructor(httpClient, appService) {
        this.httpClient = httpClient;
        this.appService = appService;
    }
    resetPassword(resetPassword) {
        resetPassword.password = this.appService.aesEncrypt(resetPassword.userName, resetPassword.password);
        resetPassword.confirmPassword = this.appService.aesEncrypt(resetPassword.userName, resetPassword.confirmPassword);
        if (resetPassword.otp) {
            resetPassword.otp = this.appService.aesEncrypt(resetPassword.userName, resetPassword.otp);
        }
        return this.httpClient.post("/Account/ResetPassword", resetPassword);
    }
    preResetPassword(preResetPassword) {
        return this.httpClient.post("/Account/PreResetPassword", preResetPassword);
    }
}
ResetPasswordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResetPasswordService_Factory() { return new ResetPasswordService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppService)); }, token: ResetPasswordService, providedIn: "root" });
