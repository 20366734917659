import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { PartnerSelectionRequestModel } from '../models/partner-selection-request.model';
import { PartnerSelectionResultModel } from '../models/partner-selection-result.model';

@Injectable({
  providedIn: 'root'
})
export class SelectPartnerService {

  constructor(private httpClient: HttpClient) { }

  selectPartner(request: PartnerSelectionRequestModel): Observable<PartnerSelectionResultModel> {   
    return this.httpClient.post<PartnerSelectionResultModel>("/Account/SelectPartner", request);
  }  
}
