import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FindUserService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    findUser(request) {
        return this.httpClient.post("/Account/Logon", request);
    }
}
FindUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FindUserService_Factory() { return new FindUserService(i0.ɵɵinject(i1.HttpClient)); }, token: FindUserService, providedIn: "root" });
