import * as cryptoJS from 'crypto-js';
import * as i0 from "@angular/core";
export class AesEncryptionService {
    constructor() {
        //Secret key and vector key will both be stored in the form of a WordArray
        this._secretKey = null;
        this._vectorKey = null;
    }
    get secretKey() {
        return this._secretKey;
    }
    get vectorKey() {
        return this._vectorKey;
    }
    set secretKey(keyToSet) {
        if (keyToSet != null && keyToSet != undefined && keyToSet != "") {
            keyToSet = this.pad_with_zeroes(keyToSet, 32);
            this._secretKey = cryptoJS.enc.Utf8.parse(keyToSet.substring(0, 32));
        }
    }
    set vectorKey(keyToSet) {
        if (keyToSet != null && keyToSet != "") {
            keyToSet = this.pad_with_zeroes(keyToSet, 16);
            this._vectorKey = cryptoJS.enc.Utf8.parse(keyToSet.substring(0, 16));
        }
    }
    //Encrypts the data based on the secret key and the vector key set beforehand
    encryptData(dataToEncrypt) {
        var encryptedString = "";
        if (dataToEncrypt != "" && dataToEncrypt != null) {
            var resultObject = cryptoJS.AES.encrypt(dataToEncrypt, this._secretKey, { iv: this._vectorKey });
            encryptedString = (resultObject.ciphertext).toString(cryptoJS.enc.Base64);
            return encryptedString.replace("/", "*");
        }
    }
    // Decrypts the data based on the secret key and the vector key set beforehand   
    decryptData(dataToDecrypt) {
        if (dataToDecrypt != null && dataToDecrypt != undefined) {
            const decryptedString = cryptoJS.AES.decrypt();
            return decryptedString;
        }
    }
    pad_with_zeroes(inputString, length) {
        while (inputString.length <= length) {
            inputString = inputString + '0';
        }
        return inputString;
    }
}
AesEncryptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AesEncryptionService_Factory() { return new AesEncryptionService(); }, token: AesEncryptionService, providedIn: "root" });
