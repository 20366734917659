<div class = "large mt-1 mb-1">
  {{'Hello' | translate}} {{username }}!
</div>
<div class="mb-2 medium">
  {{'Change_Password.ChangePasswordText' | translate}}
</div>

<form [formGroup]="changePasswordForm" class="form-floating" (ngSubmit)="changePassword()" #form="ngForm" autocomplete="off">
  <div class="d-flex justify-content-end mb-1 "><a routerLink="/ForgetCredential" [queryParams]= "{ReturnUrl: returnUrl}" (click)="forgotPasswordTab()"><span class="small">{{
    'LOGIN.ForgotPassword' | translate}}</span> </a> </div>
  <div class="form-floating mb-2">
    <input autofocus id="password" type="password" class="form-control small" formControlName="password" [ngClass]="{ 'is-invalid': form.submitted
            && formControls.password.invalid , 'is-valid': (formControls.password.dirty || formControls.password.touched)
            && formControls.password.valid }" placeholder="Password" (keydown.space)="$event.preventDefault();"
            [attr.aria-invalid]="form.submitted && formControls.password.invalid">
    <label for="password">{{ 'Change_Password.CurrentPassord' | translate}} </label>
    <div class="invalid-feedback small text-justify ">
        <div *ngIf="formControls.password.errors?.required" aria-invalid="true" role="alert">
            {{ 'LOGIN.PasswordRequired' | translate}}
        </div>
    </div>
 </div>
  <div class="form-floating mb-2">
    <input id="newPassword" [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName="newPassword"
      [ngClass]="{ 'is-invalid': form.submitted && (form.errors?.noLength && form.errors?.noNumber&& form.errors?.noSpecial && form.errors?.noUpper && form.errors?.noLower)
              && formControls.newPassword.invalid , 'is-valid': (formControls.newPassword.dirty || formControls.newPassword.touched) && formControls.newPassword.valid } " placeholder="test"
              (keydown.space)="$event.preventDefault();"
              [attr.aria-invalid]="form.submitted && (form.errors?.noLength && form.errors?.noNumber&& form.errors?.noSpecial && form.errors?.noUpper && form.errors?.noLower)
              && formControls.newPassword.invalid">
    <label for="newPassword">{{'Change_Password.NewPassword' | translate}} </label>
    <div class="invalid-feedback small text-justify">
      <div *ngIf="formControls.newPassword.errors?.required" aria-invalid="true" role="alert">
        {{'Change_Password.NewPasswordRequired' | translate}}
      </div>
    </div>

    <div class="eyetriangle">
      <span class="px-2">
        <i><fa-icon class = "red-text" [icon]="Triangle" *ngIf="(form.errors?.noUpper ||  form.errors?.noLower || form.errors?.noSpecial || form.errors?.noLength || form.errors?.noNumber)&& (formControls.newPassword.touched ||formControls.newPassword.dirty)"></fa-icon>  </i>
    </span>
    <fa-icon class = "eye" [icon]="Eye" (click)="togglePassword()" [class.hide]="!showPassword "></fa-icon>
      <fa-icon class = "eye" [icon]="EyeSlash" (click)="togglePassword()" [class.hide]="showPassword"></fa-icon>
    </div>
  </div>
  <div class="passvaltext" *ngIf=' (form.errors?.noUpper ||  form.errors?.noLower || form.errors?.noSpecial || form.errors?.noLength || form.errors?.noNumber)&& (formControls.newPassword.touched ||formControls.newPassword.dirty)'>
    <div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small my-1 "
          [ngClass]="{'green-text': !form.errors?.noLength,'red-text': form.errors?.noLength}">
          <span *ngIf="form.errors?.noLength"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noLength"> <fa-icon [icon]="Tick"></fa-icon></span>
          {{'Validations.noLength' | translate}}
        </span>
      </div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small mt-2 "
          [ngClass]="{'green-text': !form.errors?.noNumber,'red-text': form.errors?.noNumber}">
          <span *ngIf="form.errors?.noNumber"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noNumber"><fa-icon [icon]="Tick"></fa-icon> </span>
          {{'Validations.noNumber' | translate}}
        </span>
      </div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small mt-2"
          [ngClass]="{'green-text': !form.errors?.noUpper,'red-text': form.errors?.noUpper}">
          <span *ngIf="form.errors?.noUpper"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noUpper"><fa-icon [icon]="Tick"></fa-icon> </span>
          {{'Validations.noUpper' | translate}}
        </span>
      </div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small mt-2"
          [ngClass]="{'green-text': !form.errors?.noLower,'red-text': form.errors?.noLower}">
          <span *ngIf="form.errors?.noLower"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noLower"><fa-icon [icon]="Tick"></fa-icon> </span>
          {{'Validations.noLower' | translate}}
        </span>
      </div>
      <div class="mt-2" aria-invalid="true" role="alert">
        <span class="small mt-2"
          [ngClass]="{'green-text': !form.errors?.noSpecial,'red-text': form.errors?.noSpecial}">
          <span *ngIf="form.errors?.noSpecial"> <fa-icon [icon]="Cross"></fa-icon></span>
          <span *ngIf="!form.errors?.noSpecial"><fa-icon [icon]="Tick"></fa-icon> </span>
          {{'Validations.noSpecial' | translate}}
        </span>
      </div>
    </div>
  </div>
  <div class="form-floating">
    <input id="confirmPassword" type="password" class="form-control" formControlName="confirmPassword"
      [ngClass]="{ 'is-valid': formControls.confirmPassword.valid && (formControls.password.dirty || formControls.password.touched), 'is-invalid': form.submitted  && (form.errors?.notEqual && formControls.confirmPassword.touched ) } "
      placeholder="test" (keydown.space)="$event.preventDefault();"
      [attr.aria-invalid]="formControls.confirmPassword.valid && form.submitted">

    <label for="confirmPassword">{{'Change_Password.ConfirmPassword' | translate}} </label>
    <div class="invalid-feedback small text-justify red-text">
      <br>
      <div *ngIf="formControls.confirmPassword.errors?.required" aria-invalid="true" role="alert">
          {{'Change_Password.ConfirmPasswordRequired' | translate}}
      </div>
    </div>
    <div *ngIf = "form.submitted && changePasswordForm.errors?.notEqual">
      <div class="small red-text my-1">
        {{ 'Change_Password.PasswordsNotMatched' | translate}}
      </div>
    </div>     
  </div>

  <div class="red-text small text-justify">   
    <div *ngIf="changePasswordForm.errors?.passwordChangeFailed" aria-invalid="true" role="alert">
     {{' Change_Password.PasswordChangeFailed' | translate}}
    </div>
    <div *ngIf="changePasswordForm.errors?.passwordReused" aria-invalid="true" role="alert">
      {{'Change_Password.PasswordReused' | translate}}
    </div>
    <div *ngIf="changePasswordForm.errors?.passwordMismatch" aria-invalid="true" role="alert">
      {{'Change_Password.InvalidCredentials' | translate}}
    </div>
  </div>
  <div class="d-flex align-items-center mt-2">
    <button type="submit" class="btn btn-primary btn-block">
      {{'Update' | translate}}
    </button>
  </div>
</form>
<div class="text-center medium">
  <a routerLink="/Logon" [queryParams]= "{ReturnUrl: returnUrl}">{{'SignInWithDifferentUser'|translate}}</a>
</div>
<ng-template #infoTemplate>
  <div class="modal-body pb-0 small">
    <div class="d-flex align-items-center mb-2">
    <span class="modal-message-icon"><fa-icon [icon]="Tick"></fa-icon></span>
      <span class="large px-2">{{'Change_Password.PasswordChanged' | translate}}</span>
    </div>
    <p>{{'Change_Password.PasswordChangedText' | translate}}</p>
  </div>
  <div class="modal-footer pt-0 small">
    <button type="button" class="btn btn-primary" (click)="alertOk()" >{{'OK' |translate}}</button>
  </div>
</ng-template>
