import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FindUserResultModel } from '../models/find-user-result.model';
import { FindUserViewModel } from '../models/find-user.model';

@Injectable({
  providedIn: 'root'
})
export class FindUserService {

  constructor(private httpClient: HttpClient) { }
 
  findUser(request: FindUserViewModel):Observable<FindUserResultModel>{
    return this.httpClient.post<FindUserResultModel>("/Account/Logon", request);
  }
}
