import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const passwordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const newPassword = control.get("newPassword");

  const confirmPassword = control.get("confirmPassword");
  const upperCase = /[A-Z]/;
  const lowerCase = /[a-z]/;
  const number = /[0-9]/;
  const specialChar = /[~!@#$%^&*()_+?]/;
  const length = /^.{10,20}$/;
  
  const isPasswordNotEqual = newPassword.value != confirmPassword.value;
  const isNoUpper = !upperCase.test(newPassword.value);
  const isNoLower = !lowerCase.test(newPassword.value);
  const isNoNumber = !number.test(newPassword.value);
  const isNoSpecial = !specialChar.test(newPassword.value);
  const isLengthShort =  !length.test(newPassword.value);

  if (isPasswordNotEqual || isNoUpper || isNoLower || isNoNumber || isNoSpecial || isLengthShort) {
  return {notEqual: isPasswordNotEqual, noUpper:  isNoUpper, noLower: isNoLower, noNumber: isNoNumber, noSpecial:  isNoSpecial, noLength:isLengthShort};
  } else {
    return null;
  }

};
