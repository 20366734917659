/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authenticator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../node_modules/angularx-qrcode/angularx-qrcode.ngfactory";
import * as i4 from "angularx-qrcode";
import * as i5 from "@angular/common";
import * as i6 from "../multi-factor/multi-factor.component.ngfactory";
import * as i7 from "../multi-factor/multi-factor.component";
import * as i8 from "../app.service";
import * as i9 from "@angular/router";
import * as i10 from "ngx-bootstrap/modal";
import * as i11 from "../multi-factor/multi-factor.service";
import * as i12 from "@angular/platform-browser";
import * as i13 from "ngx-cookie-service";
import * as i14 from "./authenticator.component";
var styles_AuthenticatorComponent = [i0.styles];
var RenderType_AuthenticatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthenticatorComponent, data: {} });
export { RenderType_AuthenticatorComponent as RenderType_AuthenticatorComponent };
function View_AuthenticatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("MULTI_FACTOR_Authencator_Title")); _ck(_v, 1, 0, currVal_0); }); }
function View_AuthenticatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "qrcode", [["class", "align-center"]], null, null, null, i3.View_QRCodeComponent_0, i3.RenderType_QRCodeComponent)), i1.ɵdid(5, 4767744, null, 0, i4.QRCodeComponent, [i1.Renderer2, i1.PLATFORM_ID], { qrdata: [0, "qrdata"], errorCorrectionLevel: [1, "errorCorrectionLevel"], width: [2, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.authResult.authenticator.authenticatorUri; var currVal_2 = "M"; var currVal_3 = 128; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("MULTI_FACTOR_Authencator_SubTitle")); _ck(_v, 2, 0, currVal_0); }); }
export function View_AuthenticatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticatorComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-multi-factor", [], null, [[null, "fallbackToSmsEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fallbackToSmsEvent" === en)) {
        var pd_0 = (_co.onFallbackToSms($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MultiFactorComponent_0, i6.RenderType_MultiFactorComponent)), i1.ɵdid(6, 245760, null, 0, i7.MultiFactorComponent, [i8.AppService, i9.Router, i9.ActivatedRoute, i10.BsModalService, i11.MultiFactorService, i12.Title, i2.TranslateService, i13.CookieService, i1.ChangeDetectorRef], null, { fallbackToSmsEvent: "fallbackToSmsEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideAuthenticatorDiv; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.hideAuthenticatorDiv; _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); }, null); }
export function View_AuthenticatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-authenticator", [], null, null, null, View_AuthenticatorComponent_0, RenderType_AuthenticatorComponent)), i1.ɵdid(1, 114688, null, 0, i14.AuthenticatorComponent, [i8.AppService, i9.Router, i9.ActivatedRoute, i12.Title, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticatorComponentNgFactory = i1.ɵccf("app-authenticator", i14.AuthenticatorComponent, View_AuthenticatorComponent_Host_0, {}, {}, []);
export { AuthenticatorComponentNgFactory as AuthenticatorComponentNgFactory };
