import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.service";
export class LoginService {
    constructor(httpClient, appService) {
        this.httpClient = httpClient;
        this.appService = appService;
    }
    login(loginInput) {
        let encryptedLoginInput = JSON.parse(JSON.stringify(loginInput));
        encryptedLoginInput.password = this.appService.aesEncrypt(encryptedLoginInput.username, encryptedLoginInput.password);
        return this.httpClient.post("/Account/Login", encryptedLoginInput);
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppService)); }, token: LoginService, providedIn: "root" });
