<body [ngStyle]="currentImagePath && {'background-image': 'url('+ currentImagePath+ ')', '-webkit-transition': 'background-image 0.7s ease-in-out 0.7s', 'transition': 'background-image 0.7s ease-in-out 0.7s' }" class="vh-100">
    <div class="container-fluid vh-100">
        <div class="px-0 vh-100" id="main">
            <div class="section1 scale-small">
                <div class="text-div">
                    <div >
                        <p [translate]=bannerText [@bannerText]="bannerText" class="banner-text" ></p>
                    </div>
                    <div class="info-text" [@bannerText]="bannerText">
                        <p [translate]=infoText></p>
                    </div>
                    <a [href]="infoLink" target="blank" ><button class="btn-secondary mt-4"
                        attr.aria-label="{{'LearnMore' | translate}} {{ 'About' | translate}} {{ bannerText | translate}}">
                            {{'LearnMore' | translate}} 
                        </button></a>
                </div>
            </div>
            <div class="section2 scale-small">
                <div class="section4">
                    <div class="card-container">
                        <div class="card shadow-lg">
                            <div style= "margin-left: auto;" class="my-2">
                                <app-flags></app-flags>
                            </div>
                            
                            <div class="d-flex justify-content-center  h-100">
                                <img src="{{this.cdnPath}}/assets/images/gep-logo.svg" />
                            </div>
                            <div class="card-body mb-0 pb-0">
                                <router-outlet></router-outlet>
                            </div>
                            <div class="fixed-footer">
                                <div class="mb-1 pb-2 border-0">
                                    <app-footer></app-footer>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Loading spinner -->
    <div *ngIf="loading" class="loading-container flex-content-center">
        <div class="absolute-center loading-text" role="alert" aria-busy="true">
            Loading...
        </div>
    </div>
</body>