<div class="back">    
    <a [routerLink]="" (click)="goBack()"><fa-icon [icon]="ArrowLeft"></fa-icon></a>
</div>
<form class="form-floating" [formGroup]="mfaLoginForm" class="form-floating mb-1" (ngSubmit)="login()" #form="ngForm" autocomplete="off">
    <div *ngIf = "!isAuthenticator || isAuthenticatorFallbackToSms">
        <div *ngIf="isMobileOtpButNotVerified" class="my-1 small">{{'MULTI_FACTOR.MobileNotVerified' | translate}}</div>
        <div class="medium">
            <div class="bold-text bold-text-font-size mb-1">
                {{'MULTI_FACTOR.VerifyCodeTitle' | translate}}
            </div>
            <div *ngIf="maskedEmail && maskedPhoneNumber">
                <div class="form-check" >
                    <input class="form-check-input"  type="radio" value="2" formControlName="mfaChannel" name="mfaChannel" id="emailChannel">
                    <label class="form-check-label" for="emailChannel"> {{'MULTI_FACTOR.MfaEmailText' | translate}} {{maskedEmail}}  </label>
                </div>
                <div class="form-check" >
                    <input  class="form-check-input" type="radio" value="1" formControlName="mfaChannel" name="mfaChannel" id="phoneChannel">
                    <label class="form-check-label" for="phoneChannel"> {{'MULTI_FACTOR.MfaPhoneText' | translate}} {{maskedPhoneNumber}}  </label>
                </div>      
            </div>
            <div  *ngIf="maskedEmail && !maskedPhoneNumber">
                {{'MULTI_FACTOR.MfaEmailText' | translate}} {{maskedEmail}}         
            </div>
            <div  *ngIf="!maskedEmail && maskedPhoneNumber">
                {{'MULTI_FACTOR.MfaPhoneText' | translate}} {{maskedPhoneNumber}}       
            </div>
            
        </div>
    </div>
    <div *ngIf="isAuthenticator && !isAuthenticatorFallbackToSms">
        <div class="medium">
            <div class="bold-text bold-text-font-size mb-1">
                {{'MULTI_FACTOR.VerifyAuthenticatorTitle' | translate}}
            </div>
        </div>
    </div>
    <div class="form-floating mb-4">
        <input autofocus id="code" type="text" class="form-control" formControlName="code" [ngClass]="{ 'is-invalid': (formControls.code.dirty || formControls.code.touched ) && form.submitted
                    && formControls.code.invalid , 'is-valid': (formControls.code.dirty || formControls.code.touched)
                    && formControls.code.valid }" placeholder="code" appUsernameValidation
                    [attr.aria-invalid]="(formControls.code.dirty || formControls.code.touched ) && form.submitted
                    && formControls.code.invalid">
        <label for="code">{{ 'MULTI_FACTOR.EnterOtp' | translate}} </label>
        <div class="invalid-feedback position-absolute">
            <div *ngIf="formControls.code.errors?.required" aria-invalid="true" role="alert">
                {{ 'MULTI_FACTOR.OtpRequiredMessage' | translate}}
            </div>
            <div class="mb-5" *ngIf="formControls.code.errors?.incorrectCode" aria-invalid="true" role="alert">
                {{ 'MULTI_FACTOR.InCorrectOtpMessage' | translate}}
            </div>
        </div>
        <div class="eyetriangle">
            <span class="px-2">
                <i>
                    <fa-icon class="red-text" [icon]="WarningIcon"
                        *ngIf="formControls.code.invalid && form.submitted"></fa-icon>
                </i>
            </span>
        </div>
    </div>
    <div class="d-flex">
        <div class="flex-even mb-1 mt-1" [ngClass]="{'mx-2': !isAuthenticator || isAuthenticatorFallbackToSms}">
            <button type="submit"class="btn btn-primary ms-auto btn-block">
                {{ 'MULTI_FACTOR.Verify' | translate}}
            </button>
        </div>
        
        <div class="flex-even mb-1 mt-1 inline-reset-button" *ngIf="!isAuthenticator || isAuthenticatorFallbackToSms">
            <button type="button" (click)="requestToken(false)" class="btn btn-secondary ms-auto btn-block reset-button" [disabled]="count != 0? 'disabled': null" >
                {{ 'MULTI_FACTOR.ResendOtp' | translate}}
            </button>
        </div>

    </div>
    <div *ngIf="!isAuthenticator  || isAuthenticatorFallbackToSms">
        
        <div class = "mt-1 small">
            {{ 'ClickOn' | translate}}  {{ 'MULTI_FACTOR.ResendOtp' | translate}}  {{ 'MULTI_FACTOR.ResendOtpMessage' | translate}} <span class="bold-text">{{count}}</span> {{ 'Seconds' | translate}}
        </div>
    </div>
    <div *ngIf="isAuthenticator  && !isAuthenticatorFallbackToSms && isAuthenticatorFallbackToSmsAllowed">
        <p _ngcontent-jom-c4="" class="sub-title text-center">
            <a href="#" class="hover-underline" (click)="unableToUseAuthenticator($event)">{{'MULTI_FACTOR_Authencator_Unable_to_Use' | translate}}</a>
        </p>
    </div>
    
</form>
<ng-template #blocked>
    <div class="modal-body pb-0 small">
      <div class="d-flex align-items-center mb-2">
        <span class="modal-message-icon"><fa-icon class = "red-text"  [icon]="WarningIcon"></fa-icon></span>
          <span class="large px-2">{{'Warning' | translate}}</span>
        </div>
      <p class="mt-4">{{okModelRefMessage | translate}}</p>
    </div>
    <div class="modal-footer pt-0 small">
      <button type="button" class="btn btn-primary" (click)="tofinduser()" >Ok</button>
    </div>
</ng-template>