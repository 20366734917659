import { Router } from '@angular/router';
import { AppService } from '../app.service';
import * as i0 from "@angular/core";
import * as i1 from "../app.service";
import * as i2 from "@angular/router";
export class LoginGuard {
    constructor(appService, router) {
        this.appService = appService;
        this.router = router;
    }
    canActivate(next, state) {
        const returnUrl = next.queryParams.ReturnUrl;
        if (returnUrl) {
            this.appService.returnUrl = returnUrl;
        }
        if (!this.appService.username) {
            this.router.navigate(['/Logon'], { queryParams: next.queryParams });
            return false;
        }
        return true;
    }
}
LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.Router)); }, token: LoginGuard, providedIn: "root" });
