import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ForgetCredentialService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    recoverPassword(model) {
        return this.httpClient.post("/Account/RecoverPassword", model);
    }
    recoverUsernames(model) {
        return this.httpClient.post("/Account/RecoverUserNames", model);
    }
}
ForgetCredentialService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ForgetCredentialService_Factory() { return new ForgetCredentialService(i0.ɵɵinject(i1.HttpClient)); }, token: ForgetCredentialService, providedIn: "root" });
