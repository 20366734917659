import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { LayoutViewModel } from '../models/layout-view.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  cdnPath = '';
  currentYear: number = new Date().getFullYear()

  layoutView: LayoutViewModel;
  constructor(private appService: AppService) { 
  }

  ngOnInit(): void {
    this.layoutView = this.appService.layoutView;
    this.cdnPath = this.appService.layoutView.cdnPath;
  }



}
