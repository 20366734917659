import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appUsernameValidation]'
})
export class UsernameValidationDirective {

  constructor(private el: ElementRef) { }

  // Allow only alphabates, number, at the rate sign and dot sign
        // 1. a to z - 97 to 122
        // 2. A to Z - 65 to 90
        // 3. 0 to 9 - 48 to 57
        // 4. At the rate sign - 64
        // 5. . - 46
        // 6. Enter - 13
        // 7. _ - 95 (Underscore)
        // 8. / - 47 (Forward slash)
        // 9. \ - 92 (Back slash)
        //10. - - 45 (Dash/Hyphen)
  @HostListener('keypress', ['$event']) onKeyPress(event) {    
    const charCode = event.key.charCodeAt(0);
    if ((charCode >= 97 && charCode <= 122) || (charCode >= 65 && charCode <= 90)
        || (charCode >= 48 && charCode <= 57) || (charCode == 46) || (charCode == 64) || (charCode == 13) || (charCode == 95)
        || (charCode == 47) || (charCode == 92) || (charCode == 45)) {        
        return true;
    }
    else {
        return false;
    }
  }

  @HostListener('paste', ['$event']) sanitizePaste(event: KeyboardEvent) {      
    setTimeout(() => {
      const regex = /[!#$%^&*+=?;:",|<>(){}\[\]]/gi;
      this.el.nativeElement.value = this.el.nativeElement.value.replace(regex, '').replace(' ','');          
      event.preventDefault();
    },50)  ;    
  }
}
