import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ChangePasswordModel} from "../models/change-password.model"
import { Router } from "@angular/router";
import { AppService } from "../app.service";
import { ChangePasswordService } from "./change-password.service";
import { passwordValidator } from "../password.validator";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faTimesCircle,faCheckCircle,faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { faEye,faEyeSlash} from '@fortawesome/free-regular-svg-icons';


@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.scss"],
})
export class ChangepasswordComponent implements OnInit {
  Cross = faTimesCircle;
  Tick= faCheckCircle;
  Triangle = faExclamationTriangle;

  @ViewChild('infoTemplate',{static:true})
  public infoTemplateRef: TemplateRef<any>;
  username: string;
  changePasswordForm: FormGroup;
  okModalRef?: BsModalRef;
  title : string;
  public showPassword: boolean ;
  Eye = faEye;
  EyeSlash = faEyeSlash;
  returnUrl: string;
  passwordChangedTitle: string;
  passwordChangedMessage: string;
  constructor( private changePasswordService : ChangePasswordService , private router: Router, private appService: AppService
    , private modalService: BsModalService, private titleService:Title, private translate :TranslateService) {}


  get formControls() {
    return this.changePasswordForm.controls;
  }

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      password: new FormControl("", Validators.required),
      newPassword: new FormControl("", Validators.required),
      confirmPassword: new FormControl("", Validators.required),
    },
    { validators: passwordValidator }
    );
    this.username = this.appService.username;
    this.translate.get('/ChangePassword').subscribe(res=>{
      this.titleService.setTitle("GEP | "+res);
    });
    this.appService.titleKey = "/ChangePassword";
    this.returnUrl = this.appService.returnUrl;
    this.translate.get("Change_Password.PasswordChanged").subscribe(res=>{
      this.passwordChangedTitle= res;
    });
    this.translate.get("Change_Password.PasswordChangedText").subscribe(res=>{
      this.passwordChangedMessage= res;
    });
  }

togglePassword(): void {
  this.showPassword = !this.showPassword;
}
  changePassword(): void {
    if(this.changePasswordForm.invalid){
      return;
    }
    const changePassword: ChangePasswordModel = {
      username : this.appService.username,
      newPassword : this.changePasswordForm.value.newPassword,
      confirmPassword : this.changePasswordForm.value.confirmPassword,
      currentPassword: this.changePasswordForm.value.password,
    }

    this.changePasswordService.changePassword(changePassword)
    .subscribe(changePasswordResult =>{
      if (changePasswordResult.isPasswordChanged){
        this.appService.showSuccess(this.passwordChangedTitle,this.passwordChangedMessage)
        this.router.navigate(['/Logon'],{ queryParams: {ReturnUrl: this.appService.returnUrl} });
        return;
      } else {
        this.changePasswordFailed(changePasswordResult);
      }
    },(() =>{
      this.changePasswordFailed(null);
    }));
 }

 alertOk(): void{
   if (this.okModalRef){
    this.okModalRef.hide();
   }
  this.router.navigate(['/Login'],{ queryParams: {ReturnUrl: this.appService.returnUrl} });
 }
 forgotPasswordTab():void{
  this.appService.forgotPasswordTab = false;
}

  private changePasswordFailed(changePasswordResult: ChangePasswordModel) {
    setTimeout(() => {
      this.changePasswordForm.setErrors({ passwordChangeFailed: true });
      if (changePasswordResult ){
        if (changePasswordResult.isReuseOfOldPassword){
          this.changePasswordForm.setErrors({ passwordReused: true });
        }
        if (changePasswordResult.isPasswordMismatch){
          this.changePasswordForm.setErrors({ passwordMismatch: true });
        }
      }
      this.changePasswordForm.markAsTouched();
    }, 100);
  }
}
