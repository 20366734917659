import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FindOrganizationResultModel } from '../models/find-organization-result.model';
import { FindOrganizationModel } from '../models/find-organization.model';

@Injectable({
  providedIn: 'root'
})
export class FindOrganizationService {

  constructor(private httpClient: HttpClient) { }

  findOrganization(request: FindOrganizationModel):Observable<FindOrganizationResultModel>{
    return this.httpClient.post<FindOrganizationResultModel>("/Account/FindOrganization", request);
  }
}
