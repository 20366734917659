import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const forgetCredentialValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const userId = control.get('userId');
    const email = control.get('email');
    const EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email.value && !userId.value ){
        return {required: true};
    }
    else if (!userId.value && email.value && !EMAIL_REGEXP.test(email.value)){
        return {email: true};
    }
    return null;
  };