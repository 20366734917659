import { HttpClient, HttpXsrfTokenExtractor } from '@angular/common/http';
import { AesEncryptionService } from './encryption/aes-encryption.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "./encryption/aes-encryption.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-toastr";
import * as i4 from "@ngx-translate/core";
export class AppService {
    constructor(encryptionService, tokenExtractor, toastrService, httpClient, translate) {
        this.encryptionService = encryptionService;
        this.tokenExtractor = tokenExtractor;
        this.toastrService = toastrService;
        this.httpClient = httpClient;
        this.translate = translate;
        this.flagSelected = new BehaviorSubject(true);
        this.flagSelection = this.flagSelected.asObservable();
        this.cultureCode = "en-US"; //Default code
        this.translate.get("Error").subscribe(text => {
            this.errorToastTitle = text;
        });
        this.translate.get("ContactSupport").subscribe(text => {
            this.errorToastMessage = text;
        });
    }
    set authResult(authResult) {
        this._authResult = authResult;
    }
    get authResult() {
        return this._authResult;
    }
    set titleKey(titleKey) {
        this._titleKey = titleKey;
    }
    get titleKey() {
        return this._titleKey;
    }
    set layoutView(layoutView) {
        this._layoutView = layoutView;
    }
    get layoutView() {
        return this._layoutView;
    }
    set username(username) {
        this._username = username;
    }
    get username() {
        return this._username;
    }
    set firstname(firstname) {
        this._firstname = firstname;
    }
    get firstname() {
        return this._firstname;
    }
    set lastname(lastname) {
        this._lastname = lastname;
    }
    get lastname() {
        return this._lastname;
    }
    set password(password) {
        this._password = password;
    }
    get password() {
        return this._password;
    }
    set reRequestVerificationCodeSeconds(reRequestVerificationCodeSeconds) {
        this._reRequestVerificationCodeSeconds = reRequestVerificationCodeSeconds;
    }
    get reRequestVerificationCodeSeconds() {
        return this._reRequestVerificationCodeSeconds;
    }
    set returnUrl(returnUrl) {
        this._returnUrl = returnUrl;
    }
    get returnUrl() {
        return this._returnUrl;
    }
    set cultureCode(cultureCode) {
        this._cultureCode = cultureCode;
    }
    get cultureCode() {
        return this._cultureCode;
    }
    set maskedEmail(maskedEmail) {
        this._maskedEmail = maskedEmail;
    }
    get maskedEmail() {
        return this._maskedEmail;
    }
    set maskedPhoneNumber(maskedPhoneNumber) {
        this._maskedPhoneNumber = maskedPhoneNumber;
    }
    get maskedPhoneNumber() {
        return this._maskedPhoneNumber;
    }
    set organizationName(organizationName) {
        this._organizationName = organizationName;
    }
    get organizationName() {
        return this._organizationName;
    }
    set partners(partners) {
        this._partners = partners;
    }
    get partners() {
        return this._partners;
    }
    aesEncrypt(key, value) {
        this.encryptionService.secretKey = key;
        this.encryptionService.vectorKey = this.tokenExtractor.getToken();
        var encrypted = this.encryptionService.encryptData(value);
        return encrypted;
    }
    aesEncryptNew(secretKey, vectorKey, value) {
        this.encryptionService.secretKey = secretKey;
        this.encryptionService.vectorKey = vectorKey;
        var encrypted = this.encryptionService.encryptData(value);
        return encrypted;
    }
    toastServerError() {
        this.showError(this.errorToastTitle, this.errorToastMessage);
    }
    showSuccess(title, message) {
        this.toastrService.success(message, title);
    }
    showWarning(title, message) {
        this.toastrService.warning(message, title);
    }
    showError(title, message) {
        this.toastrService.error(message, title);
    }
    contextChangeGet() {
        return this.httpClient.get("/Account/ContextChangeGet");
    }
    flagSelectionChanged() {
        this.flagSelected.next(false);
    }
}
AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(i0.ɵɵinject(i1.AesEncryptionService), i0.ɵɵinject(i2.HttpXsrfTokenExtractor), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i4.TranslateService)); }, token: AppService, providedIn: "root" });
