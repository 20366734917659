import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MultiFactorService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    login(request) {
        return this.httpClient.post("/Account/MfaLogin", request);
    }
    requestToken(request) {
        return this.httpClient.post("/Account/RequestCode", request);
    }
}
MultiFactorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MultiFactorService_Factory() { return new MultiFactorService(i0.ɵɵinject(i1.HttpClient)); }, token: MultiFactorService, providedIn: "root" });
