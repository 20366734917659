<form [formGroup]="findOrganizationForm" (ngSubmit)="findOrganization()" #form="ngForm" class="form-floating" autocomplete="off">

    <div class="text-center my-3 medium">
        {{'FIND_ORGANIZATION.FindYourOrganization' | translate}}
    </div>
    <div class="form-floating mb-3">
        <input autofocus id="domainName" type="text" class="form-control" formControlName="domainName" [ngClass]="{ 'is-invalid': (formControls.domainName.dirty || formControls.domainName.touched ) && form.submitted
                && formControls.domainName.invalid , 'is-valid': (formControls.domainName.dirty || formControls.domainName.touched)
                && formControls.domainName.valid }" placeholder="domainName" appUsernameValidation
                [attr.aria-invalid]="(formControls.domainName.dirty || formControls.domainName.touched) && form.submitted
                && formControls.domainName.invalid">
        <label for="domainName">{{ 'FIND_ORGANIZATION.DomainName' | translate}} </label>
        <div class="invalid-feedback position-absolute">
            <div *ngIf="formControls.domainName.errors?.required" aria-invalid="true" role="alert">
                {{ 'FIND_ORGANIZATION.DomainNameRequiredMessage' | translate}}
            </div>
            <div class="mb-5" *ngIf="formControls.domainName.errors?.domainNotFound" aria-invalid="true" role="alert">
                {{ 'FIND_ORGANIZATION.DomainNameNotFoundMessage' | translate}}
            </div>
        </div>
        <div class="eyetriangle">
          <span class="px-2">
            <i><fa-icon class = "red-text" [icon]="WarningIcon" *ngIf="formControls.domainName.invalid && form.submitted"></fa-icon>  </i>
        </span>
        </div>
    </div>
    <div class="d-flex align-items-center mb-3 mt-5">
        <button type="submit" class="btn btn-primary ms-auto btn-block">
            {{'Next' | translate}}
        </button>
    </div>
</form>



<div class="text-center medium">
    <a routerLink="/Logon" [queryParams]= "{ReturnUrl: returnUrl}" attr.aria-label="{{'SignIn' | translate}} {{'FIND_ORGANIZATION.WithUsername' | translate}}">{{'SignIn' | translate}}</a> {{'FIND_ORGANIZATION.WithUsername' | translate}}
</div>
<div class = "mt-3 medium">
    <app-supplier-registration></app-supplier-registration>
</div>
