import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FindOrganizationService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    findOrganization(request) {
        return this.httpClient.post("/Account/FindOrganization", request);
    }
}
FindOrganizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FindOrganizationService_Factory() { return new FindOrganizationService(i0.ɵɵinject(i1.HttpClient)); }, token: FindOrganizationService, providedIn: "root" });
