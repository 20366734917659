import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginViewModel } from '../models/login-view.model';
import { AppService } from '../app.service';
import { LoginResultModel } from '../models/login-result.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient, private appService: AppService) { }

  login(loginInput: LoginViewModel): Observable<LoginResultModel> {
    let encryptedLoginInput = JSON.parse(JSON.stringify(loginInput));
    encryptedLoginInput.password = this.appService.aesEncrypt(encryptedLoginInput.username, encryptedLoginInput.password);
    return this.httpClient.post<LoginResultModel>("/Account/Login", encryptedLoginInput);
  }
}
