import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { LayoutViewModel } from '../models/layout-view.model';

@Component({
  selector: 'app-first-time-user',
  templateUrl: './first-time-user.component.html',
  styleUrls: ['./first-time-user.component.scss']
})
export class FirstTimeUserComponent implements OnInit {

  layoutView: LayoutViewModel;
  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.layoutView = this.appService.layoutView;
  }
}
